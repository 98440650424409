/* ------------------ BASE ------------------*/

@font-face {
  font-family: garetRegular;
  src: url(./assets/font//garet/Spacetype\ -\ Garet\ Regular.otf);
}

@font-face {
  font-family: garetMedium;
  src: url(./assets/font//garet/Spacetype\ -\ Garet\ Medium.otf);
}

@font-face {
  font-family: garetThin;
  src: url(./assets/font//garet/Spacetype\ -\ Garet\ Thin.otf);
}

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font-family: garetRegular, Arial, Helvetica, sans-serif;
}

:root {
    /* --accent: 236, 9, 119;  */
    --colorBrightWhite: #D9E5EC;
    --colorDark: #373433;
    --colorBright: #818B92;
    --colorGray: #C6CFD4;
    --colorGreen: #4FC86A;
    --colorRed: #D02951;
    --colorStartTitle: #3C737C;
    --backgroundBtnBright: #E8EDF0;
    --backgroundBtnLight: #FAFBFC;
    --backgroundStartFirstScreen: #4F5F6E;

}

html,
body {
  font-size: 100%;  
  overflow-x: hidden; 
  color: black;
  background: white;
  /* scroll-behavior: smooth; */
}

/* ------------------ APP => ALL ------------------ */
.app {
  width: 100%;
  max-width: 500px;
  margin: auto;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  background: var(--backgroundBtnLight);
  padding: 0 0 4rem 0;
  position: relative;
}

.screen {
  flex: 1;
  padding: 1rem 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.fullScreanSize {
  width: 100%;
  height: calc(100vh - 4rem);
}

.title {
  font-size: 1.5rem;
  color: var(--colorDark);
  font-family: garetMedium, Arial, Helvetica, sans-serif;
}

.text {
  font-size: 1rem;
  color: var(--colorDark);
  line-height: 1.5;
}

.text_white {
  color: white;
}

.text_bright {
  color: var(--colorBright);
}

.text_dark {
  color: var(--colorDark);
}

.textBold {
  font-family: garetMedium, Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.text_big{
  font-size: 1.5rem;
}

.text_very_big{
  font-size: 2rem;
}

.text_small {
  font-size: 0.8rem;
}

.text_thin {
  font-family: garetThin, Arial, Helvetica, sans-serif;
}

.text_center {
  text-align: center;
}

.text_overflow_hiden {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text_green {
  color: var(--colorGreen);
}

.text_red {
  color: var(--colorRed);
}

.img {
  width: 100%;
  height: auto;
  object-fit: fill;
}

.hr {
  width: 100%;
  height: 1px;
  background: var(--backgroundBtnBright);
  outline: none;
  border: none;
}

.svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--colorBright);
  margin: 0 0 0 0.5rem;
}

.margin2Up{
  margin: 2rem 0 0 0;
}

.margin1Up{
  margin: 1rem 0 0 0;
}

.margin1UpDown{
  margin: 1rem 0;
}

.fill_white {
  fill: white;
}

.fill_dark {
  fill: var(--colorDark);
}

.switch_text {
  font-size: 1rem;
  padding: 0.5rem;
}

.width100 {
  width: 100%;
}

/* ------------------ BTN ------------------ */

button {
  font-family: garetMedium, Arial, Helvetica, sans-serif;
}

button:active {
  box-shadow: 0 5px 20px rgba(33, 39, 43, 0.4);
}

.btn_clear {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--colorDark);
  background: transparent;
  outline: none;
  border: none;
}

.btn_clear_white {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: white;
  background: transparent;
  outline: none;
  border: none;
}


.btn_white {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--colorDark);
  background: white;
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  outline: none;
  border: none;
  box-shadow: 0 5px 10px rgba(33, 39, 43, 0.2);
}

.btn_bright {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--colorDark);
  background: var(--backgroundBtnBright);
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  outline: none;
  border: none;
  box-shadow: 0 5px 10px rgba(33, 39, 43, 0.2);
}

.btn_dark {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: white;
  background: var(--colorDark);
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  outline: none;
  border: none;
}

.btn_white_small {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--colorDark);
  background: white;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  outline: none;
  border: none;
  box-shadow: 0 5px 10px rgba(33, 39, 43, 0.2);
}

.btn_dark_small {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: white;
  background: var(--colorDark);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  outline: none;
  border: none;
}

.btn_bright_small {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--colorDark);
  background: var(--backgroundBtnBright);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  outline: none;
  border: none;
  box-shadow: 0 5px 10px rgba(33, 39, 43, 0.1);
}

.btn_bright_small2 {
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--colorDark);
  background: var(--colorGray);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  outline: none;
  border: none;
  /* box-shadow: 0 5px 10px rgba(33, 39, 43, 0.1); */
}


/* ------------------ NAV ------------------ */

.nav {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 500px;
  height: 4rem;
  margin: auto;
  background-color: #FFFFFF;
  z-index: 20;
}

.nav__container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 0.5rem 0.5rem 0.5rem;
}

.nav__item {
  width: 3rem;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--colorBright);
  fill: var(--colorBright);
  border: transparent solid;
  border-width: 0.2rem 0 0 0;
  cursor: pointer;
  position: relative;
}

.nav__item_active {
  color: var(--colorDark);
  fill: var(--colorDark);
  border-color: var(--colorDark);
}

.nav__icon {
  margin: 0.4rem 0;
}

.dot {
  position: absolute;
  top: 0.5rem;
  right: 0.7rem;
  background: red;
  padding: 0.3rem;
  border-radius: 50%;
  z-index: 10;
}

/* ------------------ Error ------------------ */

.error_text {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  color: red;
  width: 100%;
  margin: auto;
  font-size: 0.8rem;
  z-index: 100;
  word-break: break-all;
}

.error_btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  opacity: 0;
}


/* ------------------ Start ------------------ */

.start {
  width: 100%;
  min-height: 100vh;
  padding: 0 1rem 4rem 1rem;
  background: var(--backgroundBtnLight);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.start__dots_container {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 0 0 0;
}

.start__dot {
  padding: 0.25rem;
  border-radius: 50%;
  margin: 0.5rem;
}

.start__middle__content_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.start__title {
  color: var(--colorStartTitle);
}

.start__text__first_screen {
  font-size: 2rem;
  text-align: center;
  color: white;
  font-weight: 300;
}

.start__navigationBottom {
  width: 100%;
  height: 4rem;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: none;
}

.start__navigationBottom_container {
  width: 100%;
  height: 100%;
  margin: auto;
  max-width: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.home__first_screen_container_background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.home__first_screen_container_background_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.start__middle__content_img_top {
  position: absolute;
  top: 0;
  left: 0;
  width: 40%;
}
.start__middle__content_img_bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.start__middle__content_img_middle {
  width: 100%;
  max-width: 200px;
}

/* ------------------ Home ------------------ */

.home__first_screen_container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: black;
  position: relative;
  overflow-y: hidden;
}

.home__info_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  padding: 0.5rem;
}


.home__info_container_figure_main {
  width: 100%;
  /* height: 100%;  */
  max-height: 50%;
  max-width: 100%;
  max-height: auto;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.home__info_container_figure_main_img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.home__info_text_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.home__info_container_figure_small {
  width: 60%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home__stations_container{
  width: 100%;
  padding-top: 2rem;
}

.home__stations_title {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  outline: none;
}
.home__station_coins {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
}

.home__station_figure{
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home__station_figure svg{
  height: 100%;
  width: 100%;
}

.home__info_container_price {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home__info_container_price_text {
  /* color: var(--colorGreen); */
  color: white;
  font-size: 0.8rem;
  margin: 0.5rem 0 1rem 0;
}

.home__info_container_coin_text_top {
  color: var(--colorGray);
  font-size: 0.8rem;
}

.home_coinIdName {
  display: flex;
  align-items: center;
  margin: 0 0 1rem 0;
}

.home_arrow_left {
  width: 1rem;
  height: 1rem;
  background: transparent;
  border: var(--colorGray) solid;
  border-width: 0.15rem 0.15rem 0 0;
  transform: rotate(225deg);
}

.home_arrow_right {
  width: 1rem;
  height: 1rem;
  background: transparent;
  border: var(--colorGray) solid;
  border-width: 0.15rem 0.15rem 0 0;
  transform: rotate(45deg);
}

.home__info_container_coin_text_bottom {
  min-width: 7rem;
  color: white;
  font-family: garetThin, Arial, Helvetica, sans-serif;
  padding: 0 0.2rem;
  text-align: center;
}

.home__button_skaner {
  background-color: var(--colorBrightWhite);
  margin: 0 0 0 1rem
}

.home__text_green {
  color: var(--colorGreen);
}


/* ------------------ NFT ------------------ */

.nft__imgs_container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.nft__img_container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}


/* ------------------ Wallet ------------------ */

.wallet__status_container {
  width: 100%;
}

.wallet__status_container_item {
  width: 100%;
  height: 3rem;
  margin: 1rem 0;
  padding: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.1rem solid;
  border-radius: 0.5rem;
}

.wallet__status_btn {
  position: relative;
  box-shadow: none;
  min-width: 8rem;
}

.wallet__status_btn:disabled {
  color: var(--colorBright);
}

.wallet__status_text {
  padding: 0 0.2rem 0 1rem;
}


/* ------------------ Scan ------------------ */

.scan {
  background: var(--colorDark);
}

.scan__scanner {
  width: 100%;
  height: calc(100vh - 4rem);
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.scan__disconnected {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.scan__switch_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.scan__icon_constainer {
  background: var(--colorGray);
  height: 4rem;
  width: 4rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.scan__text_disconnnectTop{
  color: white;
  text-align: center;
}
.scan__text_disconnnectBottom {
  color: var(--colorGray);
  text-align: center;
}

.scan__qrReader {
  position: fixed;
  top: -3.5rem;
  left: 0;
  width: 100%;
  height: calc(100vh);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-radius: 2rem; */
  
}

.scan__qrReader section {
  height: 100%;
  max-width: 500px;
  margin: auto;
}

.scan__scaner_holder {
  height: 16rem;
}

.scan__cover_sides {
  position: fixed;
  top: calc(50% - 3.5rem);
  left: 50%;
  width: 200rem;
  height: 200rem;
  transform: translate(-50%, -50%);
  border: rgba(0, 0, 0, 0.7) solid 93rem;
  border-radius: 94rem;
  overflow: hidden; 
}

.scan__cover_border {
  position: fixed;
  top: calc(50% - 3.5rem);
  left: 50%;
  width: 14rem;
  height: 14rem;
  transform: translate(-50%, -50%);
  border: solid 0.2rem;
  border-color: rgba(255, 255, 255, 0.5);
  border-radius: 1rem;
}

.scan__info_text {
  color: white;
  text-align: center;
  padding: 1rem 0 0 0;
}

.scan_hight_index {
  z-index: 10;
}


/* ------------------ Help ------------------ */

.help__switch {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.help__img_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(33, 39, 43, 0.2);
  border-radius: 1rem;
  overflow: hidden;
  position: relative;
  
}

.help__img_container_full_screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  background: var(--backgroundBtnLight);
}

.help__img {
  /* border-radius: 1rem; */
  /* width: 100%;
  height: 100%;
  object-fit: cover; */
}

.help__link {
  display: flex;
  align-items: center;
  color: var(--colorDark);
  margin: 1rem 0;
  border-radius: 0.5rem;
}

.help__svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: var(--colorBright);
  margin: 0 0.5rem 0 0;
}

.help__svg_map {
  position: absolute;
  top: 0;
  right: 0;
  background: var(--colorDark);
  fill: white;
  padding: 0.1rem;
  margin: 0.5rem;
  border-radius: 0.2rem;
}

/* ------------------ Config ------------------ */


.config {
  width: 100%;
  position: relative;
}

.config__nav {
  width: 100%;
  background: white;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.config__nav_top {
  width: 100%;
  max-width: 500px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.config__nav_oneSide {
  display: flex;
  align-items: center;
}

.config__nav_item_container {
  display: flex;
  align-items: center;
}

.config__nav_item_number {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0.2rem;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99999px;
  color: var(--colorBright);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.config__nav_item_text {
  font-size: 0.8rem;
  margin: 0 0 0 0.5rem;
}

.config__nav_bottom {
  width: 100%;
  max-width: 500px;
  margin: auto;
  display: flex;
}

.config__nav_bottom_item {
  height: 0.125rem;
  width: 100%;
  background: var(--backgroundBtnBright);
}

.config__navigationBottom {
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: white;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.config__navigationBottom_container {
  width: 100%;
  max-width: 500px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  padding: 0 0.5rem;
}

.config__navigationBottom_line {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 0.125rem;
  width: 100%;
  background: var(--backgroundBtnBright);
  max-width: 500px;
}

.config__middle {
  min-height: 100vh;
  padding: 4rem 1rem;
  background: var(--backgroundBtnLight);
}

.config__middle__header {
  padding: 1rem;
  margin: 1rem 0 0 0;
  background: var(--backgroundBtnBright);
  border-radius: 0.5rem;
}

.config__middle__header_top_text {
  font-size: 0.8rem;
  color: var(--colorBright);
}

.config__middle__header_bottom_text {
  padding: 1rem 0 0 0;
  font-size: 1.5rem;
  color: var(--colorDark);
}

.config__middle__content {
  
}

.config__middle__content_container {

}

.config__middle__content_img {
  width: 100%;
  height: 100%;
  margin: 1rem;
  padding: 0.5rem;
  text-decoration: none;
  background: white;
  box-shadow: 0px 5px 5px rgba(33, 39, 43, 0.05), 0px 5px 25px rgba(33, 39, 43, 0.1);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.config__img {
  width: 3rem;
  height: 3rem;
}

.config__img_big {
  width: 6rem;
  height: 6rem;
  padding: 0.5rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
.config__img_big:active {
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
}

.config__middle__content_img_text {
  font-size: 0.8rem;
  color: var(--colorBright);
  text-align: center;
  padding: 0.5rem 0 0 0;
  line-height: 1.2rem;
}

.config__middle_textGreen {
  color: var(--colorGreen);
}

.config__middle_hint {
  background: var(--backgroundBtnBright);
  padding: 1rem;
  border-radius: 0.5rem;
}

.config__middle_ul {
  padding: 0 0 0 1rem;
}

.config__middle__content_container_web {
  display: flex;
}

.config__middle__content_container_web_point {
  padding: 0 0.2rem 0 0;
}

.config__middle__content_container_web_displayFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


/* ------------------ Popup ------------------ */

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  z-index: 100;
}

.popup__container {
  width: 100%;
  max-width: 500px;
  margin: auto;
  background: white;
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* ------------------ AddNftTokenInstruction ------------------ */


.addNftTokenInstruction {
  width: 100%;
  position: relative;
  padding: 1rem;
}

.config__middle {
  min-height: 100vh;
  background: var(--backgroundBtnLight);
}

.addNftTokenInstruction__figure_small {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

/* ------------------ NftScreen ------------------ */

.nftScreen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100%;
  background: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
  /* position: relative; */
}

.nftScreen__container {
  width: 100%;
  height: 100%;
  max-width: 500px;
  margin: auto;
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.nftScreen__container_btn_close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem
}

.nftScreen__container_btn_bottom {
  color: var(--colorBright);
}

.nftScreen__container_figure {
  width: 100%;
  /* height: 100%;  */
  max-height: 50%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: 0.5rem;
  position: relative;
}

.nftScreen__container_img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 0.5rem;
}

.nftScreen_spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* ------------------ Nft ------------------ */

.nft__middle_ul {
  padding: 0 0 0 1rem;
}


/* ------------------ Help ------------------ */

.aic3D_password {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.aic3D_password_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.aic3D_password_input {
  font-size: 1rem;
  min-width: 8rem;
  max-width: 16rem;
  margin: 1rem;
  padding: 0.5rem;
  -webkit-appearance: none;
  outline: none;
  border: solid 0.1rem rgba(33, 39, 43, 0.2);
  box-shadow: 0 0 10px rgba(33, 39, 43, 0.4);
}